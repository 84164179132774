:root {
    --textColor: black;
    --bgColor:#f5edd6;
    --headerBgColor: #3b5998;
    --headerTextColor:white;
    --fonts: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    --width:calc(50vw + 100px);
    --padding: calc(7vw);
    --bodyTop:100px;
    --editorTop:132px;
}

button {
    background-color: black;
    background-color: var(--textColor);
    color: #f5edd6;
    color: var(--bgColor);
    border: none;
}

button:hover {
    background-color: #f5edd6;
    background-color: var(--bgColor);
    color: black;
    color: var(--textColor);
    border-width: 1px;
    border-style: solid;
    border-color: black;
    border-color: var(--textColor); 
    margin: -1px
}

button:active {
    background-color: black;
    background-color: var(--textColor);
    color: #f5edd6;
    color: var(--bgColor);
    border: none; 
}

button:disabled {
    background-color: #f5edd6;
    background-color: var(--bgColor);
    color: #f5edd6;
    color: var(--bgColor);
    border: none; 
}

.menuContainer {
    padding: 5px;
}

body {
    color: black;
    color: var(--textColor);
    background-color: #f5edd6;
    background-color: var(--bgColor);
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-family: var(--fonts);
}

.reactMarkDown {
    color: black;
    color: var(--textColor);
    background-color: #f5edd6;
    background-color: var(--bgColor);
}

.reactMarkDown a {
    color: black;
    color: var(--textColor);
}

.reactMarkDown a:hover {
    text-decoration: overline;
}

.reactMarkDown a:visited {
    color: black;
    color: var(--textColor);
}

::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
}

.selectPost {
    width:440px;
}

#bodyContainer {
    position:fixed;
    top:100px;
    top:var(--bodyTop); 
    background-color: #f5edd6; 
    background-color: var(--bgColor);
    margin-left:calc(7vw);
    margin-left:var(--padding);
    bottom: 60px;
    width: calc(50vw + 100px);
    width: var(--width);
    height: calc(100% - 130px);
    overflow-x: hidden;
    overflow-y: scroll;
}

#saveButton {
    background-color: darkgreen;
    color:white;
}

#cancelButton {
    background-color: goldenrod;
    color: white;
}

#deleteButton {
    background-color: darkred;
    color: white;
}

.Header {
    height: 130px;
    width:calc(50vw + 100px);
    width:var(--width);
    margin-left:calc(7vw);
    margin-left:var(--padding);
    background-color: #3b5998;
    background-color: var(--headerBgColor); 
    color:white; 
    color:var(--headerTextColor);
    margin-top: -10px;
}

.Header h1 {
    padding-top: 5px;
}

.Header a {
    color: white;
    color: var(--headerTextColor);
}

.Header a:hover {
    text-decoration: overline;
}

.Header a:visited {
    color: white;
    color: var(--headerTextColor);
}

.Footer {
    position: relative;
    width:calc(50vw + 100px);
    width:var(--width);
    background-color: #3b5998;
    background-color: var(--headerBgColor);
    color:white;
    color:var(--headerTextColor);
    opacity: 1;
    bottom:0;
    height: 50px;
}

.Footer p {
    padding-left: 5px;
}

#editorTable {
    position:fixed;
    top:132px;
    width:calc(50vw + 100px);
    width:var(--width);
    margin-left:calc(7vw);
    margin-left:var(--padding);
    table-layout: fixed;
    border-style: dashed;
}

#loginBox {
    position:fixed;
    top:132px;
    margin-left:calc(7vw);
    margin-left:var(--padding);
}

#editor {
    width: calc(100% - 10px);
    height: calc(100vh - 250px);
}

#viewer {
    height: calc(66.4vh);
    width: calc(100% - 10px);
    overflow-y: scroll;
    overflow-x: visible;
    border: 1px solid black;
}

.markdownCanvas table {
    border: 1px solid;
}

.markdownCanvas th {
    border: 1px solid;
}

.markdownCanvas td {
    border: 1px solid;
}

.field {
    width:200px;
}

.loginBar {
    text-align: right;
}

#selectionBar {
    position:fixed;
    top:140px;
    margin-left:calc(7vw);
    margin-left:var(--padding);
    padding-left:20px;
    word-wrap: normal;
    width:calc(50vw + 100px);
    width:var(--width);
    overflow-y: scroll;
    height:calc(76.4vh);
}

.segoeOption {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 16pt;
}

.timesOption {
    font-family: "Times New Roman", Times, serif;
    font-size: 16pt;
}

.sourceCodeOption {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
    font-size: 16pt;
}

.arialOption {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16pt;
}

.lucidiaOption {
    font-family: "Lucida Console", "Courier New", monospace;
    font-size: 16pt;
}

.tahomaOption {
    font-family: Tahoma, Verdana, sans-serif;
    font-size: 16pt;
}

.fantasyOption {
    font-family: Fantasy, Copperplate, sans-serif;
    font-size: 16pt;
}

.fantasy2Option {
    font-family: Papyrus, sans-serif;
    font-size: 16pt;
}

.alignTop {
    vertical-align:top;
}

.wideTd {
    width: 150px;
}

.rightAlign {
    float: right;
}

.disablePage {
    position:absolute;
    top:0px;
    left:0px;
    background-color: rgba(0,0,0,0.5);
    width: 100vw;
    height: 100vh;
    z-index: 10000;
}
.floatingDialog {
    position: relative;
    top:0;
    left:0;
    right:0;
    bottom:0;
    margin: auto;
    display:table;
    /* width: fit-content;
    height:fit-content; */
    border-width: 2px;
    border-color: black;
    border-color: var(--textColor);
    border-style: solid;
    background-color: #f5edd6;
    background-color: var(--bgColor);
    padding: 10px;
    z-index: 10000;
}
#menuArea {
    width: calc(45vw);
    height: 80px;
}
.red {
    color:red;
    vertical-align: super;
    font-size: 8pt;
}
